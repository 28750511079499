import {
  CornerRadius,
  Dialog,
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { ReactElement, useCallback, useEffect } from "react";
import {
  ManageQuestionViewsDialogState,
  ManageQuestionViewsStore,
} from "../../stores/ManageQuestionViewsStore";
import {
  NavigateToFunctions,
  processSurveyViewParams,
} from "../../../../routes/RoutesHelper";
import { ManageQuestionViewsDialogChild } from "./ManageQuestionViewsDialogChild";
import { observer } from "mobx-react";
import { BorderStyle, ZERO_VALUE } from "@pulse/shared-components";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { ManageDashboardViewRPC } from "@pulse/pulse-rpcs";

interface ManageQuestionViewsDialogProps {
  isManageQuestionViewsDialogVisible: boolean;
  setIsManageQuestionViewsDialogVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  manageQuestionViewsStore: Instance<typeof ManageQuestionViewsStore>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  questionViewsSectionComponentRef: React.RefObject<HTMLDivElement>;
}

export const ManageQuestionViewsDialog = observer(
  ({
    isManageQuestionViewsDialogVisible,
    setIsManageQuestionViewsDialogVisible,
    t,
    navigateTo,
    manageQuestionViewsStore,
    spacing,
    border,
    cornerRadius,
    tokens,
    typography,
    questionViewsSectionComponentRef,
  }: ManageQuestionViewsDialogProps): ReactElement => {
    const getSurveyQuestionViews = useCallback(async () => {
      await processSurveyViewParams(
        manageQuestionViewsStore.getSurveyQuestionViews,
        navigateTo,
      );
    }, []);

    useEffect(() => {
      getSurveyQuestionViews();
      return () => {
        manageQuestionViewsStore.resetStore();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (manageQuestionViewsStore.manageQuestionViewsDialogState) {
        case ManageQuestionViewsDialogState.Error: {
          return undefined;
        }
        case ManageQuestionViewsDialogState.Loading:
        case ManageQuestionViewsDialogState.ManageQuestionViews: {
          return t("common.saveChanges");
        }
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (manageQuestionViewsStore.manageQuestionViewsDialogState) {
        case ManageQuestionViewsDialogState.Error: {
          return t("common.close");
        }
        case ManageQuestionViewsDialogState.Loading:
        case ManageQuestionViewsDialogState.ManageQuestionViews: {
          return t("common.cancel");
        }
      }
    };

    const getErrorText = (): string => {
      switch (manageQuestionViewsStore.rpcError) {
        case ManageDashboardViewRPC.RPCError.ProjectAlreadyArchived: {
          return t("common.projectAlreadyArchivedErrorText");
          break;
        }
        case ManageDashboardViewRPC.RPCError.MasterSurveyViewCannotBeEdited:
        case ManageDashboardViewRPC.RPCError.OutdatedQuestionViewsFound:
        case ManageDashboardViewRPC.RPCError.InvalidQuestionViewOrders:
        default: {
          return t("common.genericUnexpectedErrorTextWhileSavingChanges");
        }
      }
    };

    const getDialogChild = (): ReactElement => {
      switch (manageQuestionViewsStore.manageQuestionViewsDialogState) {
        case ManageQuestionViewsDialogState.Loading: {
          return (
            <LoadingIndicator
              isLoading={true}
              variant="container"
              loadingText={t("common.detailsLoadingState")}
            />
          );
        }
        case ManageQuestionViewsDialogState.ManageQuestionViews: {
          return (
            <ManageQuestionViewsDialogChild
              manageQuestionViewsStore={manageQuestionViewsStore}
              border={border}
              cornerRadius={cornerRadius}
              tokens={tokens}
              typography={typography}
              spacing={spacing}
              t={t}
            />
          );
        }
        case ManageQuestionViewsDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isManageQuestionViewsDialogVisible}
        title={t("common.manageQuestions")}
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={async () => {
          await processSurveyViewParams(
            manageQuestionViewsStore.manageSurveyQuestionViews,
            navigateTo,
          );
          if (!manageQuestionViewsStore.doesStoreContainError) {
            setIsManageQuestionViewsDialogVisible(false);
            questionViewsSectionComponentRef.current?.scrollTo({
              top: ZERO_VALUE,
              behavior: "smooth",
            });
          }
        }}
        isPrimaryButtonDisabled={
          manageQuestionViewsStore.isManageQuestionViewsDialogPrimaryButtonDisabled
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsManageQuestionViewsDialogVisible(false);
        }}
        isSecondaryButtonDisabled={manageQuestionViewsStore.isRPCLoading}
        contentPadding={
          manageQuestionViewsStore.isManageQuestionViewsDialogLoading
            ? spacing.spaceLG
            : "0px"
        }
        width="960px"
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
