import { Box, Stack, Typography } from "@mui/material";
import {
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import {
  CornerRadius,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import React, { ReactElement } from "react";
import { QUESTION_VIEW_MAX_WIDTH_IN_PIXELS } from "../../../utils/constants";
import { SurveySettingsEnums } from "@pulse/pulse-rpcs";
import { PRIMARY_FONT, RTERegularTextType } from "@pulse/shared-components";

interface LegendsProps {
  legends: string[];
  spacing: Spacing;
  cornerRadius: CornerRadius;
  colors: string[];
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isSurveyViewOptionDisplayTextSelected: boolean;
}

export const Legends = ({
  legends,
  spacing,
  cornerRadius,
  colors,
  typography,
  tokens,
  isSurveyViewOptionDisplayTextSelected,
}: LegendsProps): ReactElement => {
  return (
    <Stack
      direction="row"
      gap={spacing.spaceMD}
      maxWidth={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}
      flexWrap="wrap"
    >
      {legends.map((legend, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            gap={spacing.spaceXXS}
            justifyContent="center"
            sx={{
              "& .ProseMirror": {
                width: "fit-content",
              },
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <Box
              height="12px"
              minWidth="12px"
              borderRadius={cornerRadius.radiusMax}
              sx={{
                backgroundColor: colors[index % colors.length],
              }}
              marginTop={spacing.spaceXXS}
            />
            {isSurveyViewOptionDisplayTextSelected ? (
              <RichTextEditor
                name={legend}
                typography={richTextTypography(
                  PRIMARY_FONT,
                  SurveySettingsEnums.FontSizeScale.FontSizeScale.DEFAULT_SCALE,
                  "labelSubtle",
                  true,
                  RTERegularTextType.OPTION_TEXT,
                )}
                mode="preview"
                borderOnPreview={false}
                initialValue={legend}
                supports={RICH_TEXT_MARKDOWN_PROPS}
              />
            ) : (
              <Typography
                {...typography.b2}
                color={tokens.labelSubtle}
                textAlign="center"
              >
                {legend}
              </Typography>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
