import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import { UserAccessStore } from "../../store/UserAccessStore";
import {
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  EMPTY_CHARACTER,
  FeatureInDevelopmentSnackbar,
  ICON_SIZE,
  IconContainer,
  getFormattedDateString,
  getIconProps,
} from "@pulse/shared-components";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  TableHeader,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableSortOption,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { IconButton, Table } from "@surya-digital/leo-reactjs-material-ui";
import { ListX, MinusSquare, UserCog } from "lucide-react";
import { StyledEllipsisTypography } from "../../../../components/StyledEllipsisTypography";
import { Stack } from "@mui/material";
import { FilterComponent } from "../../../../components/filter/components/FilterComponent";
import { FilterType } from "../../../../components/filter/model/FilterModel";
import { observer } from "mobx-react";
import { SignInStore } from "../../../auth/store/SignInStore";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { DeleteSPoCDialog } from "./spocRespondent/DeleteSPoCDialog";

interface ManageSPoCsProps {
  userAccessStore: Instance<typeof UserAccessStore>;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  signInStore: Instance<typeof SignInStore>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export enum ManageSPoCsListHeaderEnum {
  UserName = "User Name",
  EmailAddress = "Email Address",
  AddedOn = "Added On",
  ExpiresOn = "Expires On",
  ManageSPoCsAction = "Manage SPoCs Action",
}

export const ManageSPoCsPane = observer(
  ({
    userAccessStore,
    t,
    tokens,
    navigateTo,
    typography,
    spacing,
    signInStore,
    tableRef,
  }: ManageSPoCsProps): React.ReactElement => {
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [isDeleteSPoCDialogOpen, setIsDeleteSPoCDialogOpen] = useState(false);

    useEffect(() => {
      if (userAccessStore.isURLInvalid) {
        userAccessStore.resetStore();
        navigateTo.pageNotFound();
      }
    }, [userAccessStore.isURLInvalid]);

    useEffect(() => {
      return (): void => {
        userAccessStore.multiSelectTableStore.unselectAllRows();
      };
    }, []);

    const filterList: { key: string; label: string }[] = [
      {
        key: ManageSPoCsListHeaderEnum.AddedOn,
        label: t("projects.userAccess.manageSPoCsTableHeaders.addedOn"),
      },
      {
        key: ManageSPoCsListHeaderEnum.EmailAddress,
        label: t("projects.userAccess.manageSPoCsTableHeaders.emailAddress"),
      },
      {
        key: ManageSPoCsListHeaderEnum.ExpiresOn,
        label: t("projects.userAccess.manageSPoCsTableHeaders.addedOn"),
      },
      {
        key: ManageSPoCsListHeaderEnum.UserName,
        label: t("projects.userAccess.manageSPoCsTableHeaders.name"),
      },
    ];

    const getProjectSPoCs = async (
      pageIndex: number,
      sort?: TableSortOption,
    ): Promise<void> => {
      await processProjectParams(async (projectId) => {
        await userAccessStore.getProjectSpocs(pageIndex, projectId, sort);
      }, navigateTo);
    };

    const getHeaders = (): TableHeader => {
      const spocListHeaders = [
        {
          columnId: ManageSPoCsListHeaderEnum.UserName,
          label: t("projects.userAccess.manageSPoCsTableHeaders.name"),
          // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
          width: "434px",
          sortable: true,
        },
        {
          columnId: ManageSPoCsListHeaderEnum.EmailAddress,
          label: t("projects.userAccess.manageSPoCsTableHeaders.emailAddress"),
          // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
          width: "434px",
        },
        {
          columnId: ManageSPoCsListHeaderEnum.AddedOn,
          label: t("projects.userAccess.manageSPoCsTableHeaders.addedOn"),
          width: "180px",
          sortable: true,
        },
        {
          columnId: ManageSPoCsListHeaderEnum.ExpiresOn,
          label: t("projects.userAccess.manageSPoCsTableHeaders.expiryDate"),
          // Below width is greater than in the design to keep the text on the same line.
          width: "200px",
          sortable: true,
        },
      ];
      if (signInStore.isPrivilegeGranted(UserPrivileges.processSpocUser)) {
        spocListHeaders.push({
          columnId: ManageSPoCsListHeaderEnum.ManageSPoCsAction,
          label: EMPTY_CHARACTER,
          width: "120px",
          sortable: false,
        });
      }

      return spocListHeaders;
    };

    const getProjectSPoCsList = async (
      options: TableOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItem[]> => {
      userAccessStore.resetInitialSelectedSPoCDetails();
      if (!userAccessStore.manageProjectSPoCsListFilter.areNoFiltersAdded) {
        await getProjectSPoCs(DEFAULT_PROJECT_LIST_SORT_INDEX, options.sort);
      } else {
        await getProjectSPoCs(
          options.pageNumber
            ? options.pageNumber - 1
            : DEFAULT_PROJECT_LIST_SORT_INDEX,
          options.sort,
        );
      }

      setTotalItems(userAccessStore.totalItems);

      return userAccessStore.projectsSPoCList.map(
        ({ emailAddress, expiresOn, createdOn, fullName: name, spocId }) => {
          const projectsSpocListCells = [
            {
              data: (
                <StyledEllipsisTypography
                  data={name}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ManageSPoCsListHeaderEnum.UserName,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={emailAddress}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ManageSPoCsListHeaderEnum.EmailAddress,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={getFormattedDateString(createdOn)}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ManageSPoCsListHeaderEnum.AddedOn,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={getFormattedDateString(expiresOn)}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ManageSPoCsListHeaderEnum.ExpiresOn,
            },
          ];
          if (signInStore.isPrivilegeGranted(UserPrivileges.processSpocUser)) {
            projectsSpocListCells.push({
              columnId: ManageSPoCsListHeaderEnum.ManageSPoCsAction,
              data: (
                <Stack flexDirection="row" gap={spacing.spaceXS}>
                  <IconButton
                    icon={<UserCog />}
                    color="primary"
                    name="editButton"
                    variant="plain-neutral"
                    size="small"
                    onClick={() => {
                      processProjectParams(async (projectId): Promise<void> => {
                        navigateTo.spocRespondent(projectId, spocId);
                      }, navigateTo);
                    }}
                  />
                  <IconButton
                    icon={<MinusSquare />}
                    color="destructive"
                    name="deleteButton"
                    variant="plain-color"
                    size="small"
                    onClick={() => {
                      userAccessStore.multiSelectTableStore.unselectAllRows();
                      userAccessStore.setInitialSelectedSPoCDetails(spocId);
                      setIsDeleteSPoCDialogOpen(true);
                    }}
                  />
                </Stack>
              ),
            });
          }
          return {
            id: spocId,
            cells: projectsSpocListCells,
          };
        },
      );
    };

    return (
      <Stack
        overflow="auto"
        height="100%"
        padding={spacing.spaceXL}
        gap={spacing.spaceLG}
        alignItems="center"
        minWidth="1376px"
        margin="auto"
      >
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
        {isDeleteSPoCDialogOpen && (
          <DeleteSPoCDialog
            isDeleteSPoCDialogOpen={isDeleteSPoCDialogOpen}
            t={t}
            spacing={spacing}
            userAccessStore={userAccessStore}
            setIsDeleteSPoCDialogOpen={setIsDeleteSPoCDialogOpen}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            navigateOnSuccess={false}
            tableRef={tableRef}
          />
        )}
        <Stack width="100%">
          <FilterComponent
            isDisabled={
              userAccessStore.isRPCLoading ||
              userAccessStore.doesStoreContainProjectListErrors
            }
            store={userAccessStore.manageProjectSPoCsListFilter}
            onApplyFiltersClick={async () => {
              tableRef.current?.reload();
            }}
            onRemoveFiltersClick={() => {
              tableRef.current?.reload();
            }}
            filters={filterList.map((column) => {
              if (
                column.key === ManageSPoCsListHeaderEnum.AddedOn ||
                column.key === ManageSPoCsListHeaderEnum.ExpiresOn
              ) {
                return {
                  ...column,
                  valueType: FilterType.DateRange,
                };
              } else {
                return {
                  ...column,
                  valueType: FilterType.OpenEnded,
                };
              }
            })}
          />
        </Stack>
        <Stack minWidth="100%">
          <Table
            ref={tableRef}
            name={"manageSPoCsList"}
            header={getHeaders()}
            onTableOptionsChange={getProjectSPoCsList}
            viewOverrides={{
              empty: {
                icon: IconContainer(
                  <ListX
                    {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
                  />,
                ),
                message: t("common.noSPoCFoundText"),
              },
              loading: { message: t("common.searchTableLoadingState") },
            }}
            leftPinnedColumnIds={[]}
            rightPinnedColumnIds={[ManageSPoCsListHeaderEnum.ManageSPoCsAction]}
            paginationOption={{
              itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
              getPageIndicatorText(startItem, endItem, totalItems): string {
                return t("common.paginationIndicationText", {
                  startItem,
                  endItem,
                  totalItems,
                });
              },
              position: "BOTH",
            }}
            styleOverrides={{
              background: tokens.backgroundElevatedLevel1,
              divider: "row",
            }}
            multiSelectOptions={{
              selectAllItemsText: t(
                "common.multiSelectTableTexts.selectAllItems",
                {
                  totalItemCount: userAccessStore.totalItems,
                },
              ),
              clearAllItemsText: t(
                "common.multiSelectTableTexts.clearAllSelection",
              ),
              clearSelectionText: t(
                "common.multiSelectTableTexts.clearSelection",
              ),
              selectionText: t("common.multiSelectTableTexts.selection", {
                selectedItemsCount:
                  userAccessStore.multiSelectTableStore.totalSelectedItems(
                    userAccessStore.totalItems,
                  ),
              }),
              shouldPinSelectionColumn: true,
              store: userAccessStore.multiSelectTableStore,
              actionElements: [
                {
                  label: t("projects.spocRespondent.remove"),
                  name: t("projects.spocRespondent.remove"),
                  onClick: (): void => {
                    setIsDeleteSPoCDialogOpen(true);
                  },
                  variant: "outlined-color",
                  color: "destructive",
                },
              ],
            }}
          />
        </Stack>
      </Stack>
    );
  },
);
