import { Stack } from "@mui/material";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  Shadow,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { t } from "i18next";
import { Trash2Icon, Download, UploadIcon } from "lucide-react";
import { SectionHeaderProps } from "../../../components/SectionHeader";
import { RespondentUploadStore } from "../store/RespondentUploadStore";
import { Button } from "@surya-digital/leo-reactjs-material-ui";
import React, { useEffect, useState } from "react";
import { Instance } from "mobx-state-tree";
import { RespondentStore } from "../store/RespondentStore";
import { observer } from "mobx-react";
import { DownloadRespondentCSVDialog } from "./downloadRespondents/DownloadRespondentCSVDialog";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { ProjectDetailsStore } from "../store/ProjectDetailsStore";
import { DeleteColumnDialog } from "./deleteColumn/DeleteColumnDialog";
import { RespondentFileUploadComponent } from "./RespondentFileUploadComponent";
import { ValidateRespondentColumnDialog } from "./duplicateValidation/ValidateRespondentColumnDialog";
import { EMPTY_CHARACTER, BorderStyle } from "@pulse/shared-components";
import { RespondentTitleActionElement } from "./RespondentTitleActionElement";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { SignInStore } from "../../auth/store/SignInStore";

export const getRespondentSectionHeader = (
  respondentStore: Instance<typeof RespondentStore>,
  isUpdateRespondentPrivilegeGranted: boolean,
  respondentUploadStore: Instance<typeof RespondentUploadStore> | null,
  tokens: FoundationColorTokens<string>,
  projectStore: Instance<typeof ProjectDetailsStore>,
  navigateTo: NavigateToFunctions,
  typography: LeoTypography,
  borderRadius: CornerRadius,
  spacing: Spacing,
  border: BorderStyle,
  boxShadow: Shadow,
  tableRef: React.MutableRefObject<TableReloadHandle | null>,
  signInStore: Instance<typeof SignInStore>,
): SectionHeaderProps => {
  const ActionElement = observer((): React.ReactElement => {
    const [
      isDownloadRespondentCSVDialogVisible,
      setIsDownloadRespondentCSVDialogVisible,
    ] = useState(false);

    useEffect(() => {
      if (respondentUploadStore?.isFileInvalid) {
        respondentUploadStore.setIsDuplicateValidationDialogVisible(false);
        respondentUploadStore.setIsRespondentUploadDialogVisible(true);
      }
    }, [respondentUploadStore?.isFileInvalid]);

    useEffect(() => {
      respondentUploadStore?.setSelectedUniqueColumn(EMPTY_CHARACTER);
    }, [respondentUploadStore?.isDuplicateValidationDialogVisible]);

    const RespondentSectionHeaderActionElements = observer(
      (): React.ReactElement => {
        if (!respondentStore.areRespondentSectionHeaderActionElementsVisible) {
          return <></>;
        } else if (respondentStore.isPaginatedListVisible) {
          return (
            <Stack direction="row" gap={spacing.spaceSM}>
              {isUpdateRespondentPrivilegeGranted && (
                <Button
                  name="deleteColumn"
                  size="medium"
                  variant="outlined-neutral"
                  onClick={() => {
                    respondentStore.respondentUIStore.setIsDeleteColumnDialogVisible(
                      true,
                    );
                  }}
                  label={t("projects.respondentList.deleteColumn")}
                  icon={<Trash2Icon />}
                  iconPosition="leading"
                  disabled={respondentStore.isDeleteColumnDisabled}
                />
              )}
              <Button
                name="downloadCSV"
                size="medium"
                variant="outlined-neutral"
                onClick={() => {
                  setIsDownloadRespondentCSVDialogVisible(true);
                }}
                label={t("common.downloadCSV")}
                icon={<Download />}
                iconPosition="leading"
              />
              {isUpdateRespondentPrivilegeGranted && (
                <Button
                  name="reUploadCSV"
                  size="medium"
                  variant="filled"
                  onClick={() => {
                    respondentUploadStore?.setIsRespondentUploadDialogVisible(
                      true,
                    );
                  }}
                  label={t("projects.respondentList.reUploadCSV")}
                  icon={<UploadIcon />}
                  iconPosition="leading"
                />
              )}
            </Stack>
          );
        } else {
          return isUpdateRespondentPrivilegeGranted ? (
            <Button
              name="uploadCSV"
              size="medium"
              variant="filled"
              onClick={() => {
                respondentUploadStore?.setIsRespondentUploadDialogVisible(true);
              }}
              label={t("projects.respondentList.uploadCSV")}
              icon={<UploadIcon />}
              iconPosition="leading"
            />
          ) : (
            <></>
          );
        }
      },
    );

    return (
      <>
        <RespondentSectionHeaderActionElements />
        <ValidateRespondentColumnDialog
          t={t}
          spacing={spacing}
          respondentUploadStore={respondentStore.respondentUploadStore}
          tokens={tokens}
          typography={typography}
          respondentStore={respondentStore}
        />
        <RespondentFileUploadComponent
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          navigateTo={navigateTo}
          respondentUploadStore={respondentStore.respondentUploadStore}
          projectStore={projectStore}
        />
        <DeleteColumnDialog
          isDeleteColumnDialogVisible={
            respondentStore.respondentUIStore.isDeleteColumnDialogVisible
          }
          setIsDeleteColumnDialogVisible={
            respondentStore.respondentUIStore.setIsDeleteColumnDialogVisible
          }
          t={t}
          respondentStore={respondentStore}
          navigateTo={navigateTo}
          tokens={tokens}
          typography={typography}
          spacing={spacing}
        />
        <DownloadRespondentCSVDialog
          isDownloadRespondentCSVDialogVisible={
            isDownloadRespondentCSVDialogVisible
          }
          setIsDownloadRespondentCSVDialogVisible={
            setIsDownloadRespondentCSVDialogVisible
          }
          t={t}
          projectStore={projectStore}
          navigateTo={navigateTo}
          tokens={tokens}
          typography={typography}
          spacing={spacing}
        />
      </>
    );
  });

  return {
    title: t("surveys.respondents"),
    actionElement: <ActionElement />,
    prefersLargeTitle: true,
    titleActionElement: signInStore.isPrivilegeGranted(
      UserPrivileges.processRespondentSpocAction,
    ) ? (
      <RespondentTitleActionElement
        t={t}
        border={border}
        boxShadow={boxShadow}
        tableRef={tableRef}
        borderRadius={borderRadius}
        tokens={tokens}
        respondentStore={respondentStore}
        typography={typography}
        spacing={spacing}
      />
    ) : undefined,
  };
};
