import { Instance, flow, getRoot, types } from "mobx-state-tree";
import { CommonErrors, NetworkingError } from "@pulse/shared-components";
import { getAPIClient } from "../../networking/APIClient";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RequestSurveyReportRPC } from "@pulse/pulse-rpcs";
import { useRequestSurveyReportRPCClientImpl } from "../rpcs/RPC";
import {
  SurveyIdAndNameModel,
  createSurveyIdAndNameModel,
} from "../models/SurveyIdAndNameModel";
import { RootStore } from "../../root/store/RootStore";
import { EmbedStore, createEmbedStore } from "./EmbedStore";

export enum DownloadReportsDialogState {
  ReportGenerationSuccess = "Report Generation Success",
  ReportGenerationError = "Report Generation Error",
  DownloadPreference = "Download Preferences",
  NoSurveyCreated = "No Survey Created",
}

export enum SurveyReportError {
  SurveyIsInDraftState = "SURVEY_IS_IN_DRAFT_STATE",
  NoSurveyAnswerOrLinkFound = "NO_SURVEY_ANSWER_OR_LINK_FOUND",
}

export enum ReportType {
  CODED = "CODED",
  NON_CODED = "NON_CODED",
}

export enum LinkType {
  LIVE = "LIVE",
  TEST = "TEST",
}

export const ReportStore = types
  .model("ReportStore", {
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration("rpcErrors", Object.values(SurveyReportError)),
        types.enumeration("networkErrors", Object.values(NetworkingError)),
      ),
    ),
    downloadReportsDialogState: types.optional(
      types.enumeration(
        "downloadReportsDialogState",
        Object.values(DownloadReportsDialogState),
      ),
      DownloadReportsDialogState.DownloadPreference,
    ),
    isRPCLoading: types.optional(types.boolean, false),
    selectedReportType: types.optional(
      types.enumeration(Object.values(ReportType)),
      ReportType.CODED,
    ),
    selectedLinkType: types.optional(
      types.enumeration("LinkType", Object.values(LinkType)),
      LinkType.LIVE,
    ),
    selectedSurvey: types.maybe(SurveyIdAndNameModel),
    embedStore: EmbedStore,
  })
  .views((store) => ({
    get isDownloadReportsDialogPrimaryButtonDisabled(): boolean {
      return store.selectedSurvey === undefined || store.isRPCLoading;
    },
    get isDownloadReportsDialogPrimaryButtonVisible(): boolean {
      return (
        store.rpcErrors === null &&
        store.downloadReportsDialogState !==
          DownloadReportsDialogState.ReportGenerationSuccess &&
        store.downloadReportsDialogState !==
          DownloadReportsDialogState.NoSurveyCreated
      );
    },
    get isDownloadReportsContentPaddingPresent(): boolean {
      return (
        store.downloadReportsDialogState ===
        DownloadReportsDialogState.DownloadPreference
      );
    },
  }))
  .actions((store) => ({
    setSelectedLinkType: (newSelectedLinkType: LinkType): void => {
      store.selectedLinkType = newSelectedLinkType;
    },
    setSelectedReportType: (newSelectedReportType: ReportType): void => {
      store.selectedReportType = newSelectedReportType;
    },
    setSelectedSurvey: (surveyId: string, surveyName: string): void => {
      store.selectedSurvey = createSurveyIdAndNameModel(surveyId, surveyName);
    },
    resetSelectedSurvey: (): void => {
      store.selectedSurvey = undefined;
    },
    clearStore: (): void => {
      store.downloadReportsDialogState =
        DownloadReportsDialogState.DownloadPreference;
      store.rpcErrors = null;
      store.selectedLinkType = LinkType.LIVE;
      store.selectedReportType = ReportType.CODED;
      store.selectedSurvey = undefined;
    },
    setDownloadReportsDialogState: (
      newDownloadReportsDialogState: DownloadReportsDialogState,
    ): void => {
      store.downloadReportsDialogState = newDownloadReportsDialogState;
    },
    requestSurveyReport: flow(function* (projectId: string) {
      try {
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        if (store.selectedSurvey === undefined) {
          console.error("Selected Survey cannot be undefined in this flow.");
          store.isRPCLoading = false;
          return;
        }
        const request = new RequestSurveyReportRPC.Request(
          new LeoUUID(store.selectedSurvey.surveyId),
          new LeoUUID(projectId),
          store.selectedReportType,
          store.selectedLinkType,
        );
        const {
          response,
          error,
        }: {
          response?: RequestSurveyReportRPC.Response;
          error?: RequestSurveyReportRPC.Errors.Errors;
        } =
          yield useRequestSurveyReportRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.downloadReportsDialogState =
            DownloadReportsDialogState.ReportGenerationSuccess;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case SurveyReportError.NoSurveyAnswerOrLinkFound:
              store.rpcErrors = SurveyReportError.NoSurveyAnswerOrLinkFound;
              break;
            case SurveyReportError.SurveyIsInDraftState:
              store.rpcErrors = SurveyReportError.SurveyIsInDraftState;
              break;
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
          store.downloadReportsDialogState =
            DownloadReportsDialogState.ReportGenerationError;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
          store.downloadReportsDialogState =
            DownloadReportsDialogState.ReportGenerationError;
        } else {
          console.error(`Unhandled error ${e} in request survey report.`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createReportStore = (): Instance<typeof ReportStore> => {
  return ReportStore.create({ embedStore: createEmbedStore() });
};
