import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { UserLocalStorageKeys } from "./UserLocalStorageKeys";
import { Instance } from "mobx-state-tree";
import { UserNameModel, createUserNameModel } from "../../models/UserNameModel";

// Root store should use the persisted privileges to check if the user is logged in or not.
// User store should use the persisted value to start its initial value.
export const getPersistedUserPrivileges = (): string[] => {
  const privilegeString = localStorage.getItem(UserLocalStorageKeys.privileges);
  if (privilegeString) {
    return privilegeString.split(",");
  }
  return [];
};

export const getPersistedUserName = (): Instance<typeof UserNameModel> => {
  const firstName = localStorage.getItem(UserLocalStorageKeys.firstName);
  const lastName = localStorage.getItem(UserLocalStorageKeys.lastName);
  return createUserNameModel(
    firstName ?? EMPTY_CHARACTER,
    lastName ?? EMPTY_CHARACTER,
  );
};

export const getPersistedUserId = (): string => {
  const userId = localStorage.getItem(UserLocalStorageKeys.userId);
  if (userId) {
    return userId;
  }
  return EMPTY_CHARACTER;
};

export const removePersistedUserPrivileges = (): void => {
  localStorage.removeItem(UserLocalStorageKeys.privileges);
  localStorage.removeItem(UserLocalStorageKeys.firstName);
  localStorage.removeItem(UserLocalStorageKeys.lastName);
  localStorage.removeItem(UserLocalStorageKeys.userId);
};

export enum UserPrivileges {
  processProject = "PROCESS_PROJECT",
  processSurvey = "PROCESS_SURVEY",
  processRespondentSurveyAction = "PROCESS_RESPONDENT_SURVEY_ACTION",
  viewSurveys = "VIEW_SURVEYS",
  viewSpocs = "VIEW_SPOCS",
  viewRespondentSPocs = "VIEW_RESPONDENT_SPOCS",
  viewProjectBOUs = "VIEW_PROJECT_BO_USERS",
  processSpocUser = "PROCESS_SPOC_USER",
  processProjectBOUser = "PROCESS_PROJECT_BO_USER",
  viewSurveyQuestions = "VIEW_SURVEY_QUESTIONS",
  viewSurveyResults = "VIEW_SURVEY_RESULTS",
  viewSurveyReport = "VIEW_SURVEY_REPORT",
  processRespondentSpocAction = "PROCESS_RESPONDENT_SPOC_ACTION",
  updateSurveyRules = "UPDATE_SURVEY_RULES",
  viewAllProjects = "VIEW_ALL_PROJECTS",
  viewRespondent = "VIEW_RESPONDENT",
  processRespondent = "PROCESS_RESPONDENT",
  processSurveyQuestion = "PROCESS_SURVEY_QUESTION",
  viewAuditLogs = "VIEW_AUDIT_LOGS",
  viewManageUsers = "VIEW_MANAGE_USERS",
  viewCreatedAndAssignedProjects = "VIEW_CREATED_AND_ASSIGNED_PROJECTS",
  viewAssignedProjects = "VIEW_ASSIGNED_PROJECTS",
  viewProjectSpocNames = "VIEW_PROJECT_SPOC_NAMES",
  viewEmbeds = "VIEW_EMBEDS",
  processEmbed = "PROCESS_EMBED",
  updateUser = "UPDATE_USER",
  processSurveyDashboardViews = "PROCESS_SURVEY_DASHBOARD_VIEW",
  processSurveyDashboardPreferences = "PROCESS_SURVEY_DASHBOARD_PREFERENCES",
}
