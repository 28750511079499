import { Stack } from "@mui/material";
import { BorderStyle, ICON_SIZE } from "@pulse/shared-components";
import {
  ButtonIcon,
  CornerRadius,
  FoundationColorTokens,
  IconButton,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { ReactElement } from "react";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { observer } from "mobx-react";
import {
  GridMultiSelectGraphTypeV1,
  GridSingleSelectGraphTypeV1,
  MultiSelectGraphTypeV1,
  OpenEndedGraphTypeV1,
  RankingGraphTypeV1,
  SingleSelectGraphTypeV1,
  SingleSelectNpsGraphTypeV1,
  SingleSelectWithProportionGraphTypeV1,
} from "@pulse/pulse-rpcs";
import {
  AreaChart,
  BarChart3,
  BarChartBig,
  BarChartHorizontal,
  CaseSensitive,
  CircleDashed,
  Gauge,
  LineChart,
  Minus,
  PieChart,
  Table,
} from "lucide-react";
import { ChartColumnStacked } from "../../../assets/icons/ChartColumnStacked";
import { HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME } from "../../utils/constants";

export enum QuestionTypeGraphs {
  PIE = "PIE",
  DONUT = "DONUT",
  HORIZONTAL_BAR = "HORIZONTAL_BAR",
  VERTICAL_BAR = "VERTICAL_BAR",
  LINE = "LINE",
  LINE_AREA = "LINE_AREA",
  GAUGE = "GAUGE",
  PROPORTION = "PROPORTION",
  NONE = "NONE",
  WORD_CLOUD = "WORD_CLOUD",
  STACKED_COLUMN = "STACKED_COLUMN",
  GROUPED_BAR = "GROUPED_BAR",
}

interface GraphIconsProps {
  selectedGraph: QuestionTypeGraphs;
  surveyQuestionViewType: SurveyQuestionViewStoreNames;
  onIconClick?: (selectedGraph: QuestionTypeGraphs) => void;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
}

const GraphIcons = observer(
  ({
    selectedGraph,
    surveyQuestionViewType,
    onIconClick,
    spacing,
    tokens,
  }: GraphIconsProps): ReactElement => {
    const GetIconButtonIcon = ({
      icon,
    }: {
      icon: QuestionTypeGraphs;
    }): ButtonIcon => {
      const size = { height: ICON_SIZE.small, width: ICON_SIZE.small };
      switch (icon) {
        case QuestionTypeGraphs.PIE:
          return <PieChart {...size} />;
        case QuestionTypeGraphs.DONUT:
          return <CircleDashed {...size} />;
        case QuestionTypeGraphs.HORIZONTAL_BAR:
          return (
            <BarChartHorizontal
              height={ICON_SIZE.small}
              width={ICON_SIZE.small}
            />
          );
        case QuestionTypeGraphs.VERTICAL_BAR:
          return <BarChart3 {...size} />;
        case QuestionTypeGraphs.LINE:
          return <LineChart {...size} />;
        case QuestionTypeGraphs.LINE_AREA:
          return <AreaChart {...size} />;
        case QuestionTypeGraphs.GAUGE:
          return <Gauge {...size} />;
        case QuestionTypeGraphs.PROPORTION:
          return <Table {...size} />;
        case QuestionTypeGraphs.NONE:
          return <Minus {...size} />;
        case QuestionTypeGraphs.WORD_CLOUD:
          return <CaseSensitive {...size} />;
        case QuestionTypeGraphs.STACKED_COLUMN:
          return (
            <ChartColumnStacked
              fillColor={
                selectedGraph === QuestionTypeGraphs.STACKED_COLUMN
                  ? tokens.iconOnSurface
                  : tokens.iconPrimary
              }
              props={{ ...size }}
            />
          );
        case QuestionTypeGraphs.GROUPED_BAR:
          return <BarChartBig {...size} />;
      }
    };

    switch (surveyQuestionViewType) {
      case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(SingleSelectGraphTypeV1.SingleSelectGraphTypeV1).map(
              (singleSelectGraphTypeV1, index) => {
                return (
                  <IconButton
                    key={index}
                    name={singleSelectGraphTypeV1}
                    size="small"
                    icon={
                      <GetIconButtonIcon
                        icon={QuestionTypeGraphs[singleSelectGraphTypeV1]}
                      />
                    }
                    variant={
                      selectedGraph ===
                      QuestionTypeGraphs[singleSelectGraphTypeV1]
                        ? "filled"
                        : "plain-color"
                    }
                    onClick={() => {
                      if (onIconClick) {
                        onIconClick(
                          QuestionTypeGraphs[singleSelectGraphTypeV1],
                        );
                      }
                    }}
                  />
                );
              },
            )}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(
              SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1,
            ).map((singleSelectNpsGraphTypeV1, index) => {
              return (
                <IconButton
                  key={index}
                  name={singleSelectNpsGraphTypeV1}
                  size="small"
                  icon={
                    <GetIconButtonIcon
                      icon={QuestionTypeGraphs[singleSelectNpsGraphTypeV1]}
                    />
                  }
                  variant={
                    selectedGraph ===
                    QuestionTypeGraphs[singleSelectNpsGraphTypeV1]
                      ? "filled"
                      : "plain-color"
                  }
                  onClick={() => {
                    if (onIconClick) {
                      onIconClick(
                        QuestionTypeGraphs[singleSelectNpsGraphTypeV1],
                      );
                    }
                  }}
                />
              );
            })}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(
              SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1,
            ).map((singleSelectWithProportionGraphTypeV1, index) => {
              return (
                <IconButton
                  key={index}
                  name={singleSelectWithProportionGraphTypeV1}
                  size="small"
                  icon={
                    <GetIconButtonIcon
                      icon={
                        QuestionTypeGraphs[
                          singleSelectWithProportionGraphTypeV1
                        ]
                      }
                    />
                  }
                  variant={
                    selectedGraph ===
                    QuestionTypeGraphs[singleSelectWithProportionGraphTypeV1]
                      ? "filled"
                      : "plain-color"
                  }
                  onClick={() => {
                    if (onIconClick) {
                      onIconClick(
                        QuestionTypeGraphs[
                          singleSelectWithProportionGraphTypeV1
                        ],
                      );
                    }
                  }}
                />
              );
            })}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.GRID_OPEN_ENDED_MODEL:
      case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1).map(
              (openEndedGraphTypeV1, index) => {
                return (
                  <IconButton
                    key={index}
                    name={openEndedGraphTypeV1}
                    size="small"
                    icon={
                      <GetIconButtonIcon
                        icon={QuestionTypeGraphs[openEndedGraphTypeV1]}
                      />
                    }
                    variant={
                      selectedGraph === QuestionTypeGraphs[openEndedGraphTypeV1]
                        ? "filled"
                        : "plain-color"
                    }
                    onClick={() => {
                      if (onIconClick) {
                        onIconClick(QuestionTypeGraphs[openEndedGraphTypeV1]);
                      }
                    }}
                  />
                );
              },
            )}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_PROPORTION_MODEL:
      case SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(
              GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1,
            ).map((singleSelectGraphTypeV1, index) => {
              return (
                <IconButton
                  key={index}
                  name={singleSelectGraphTypeV1}
                  size="small"
                  icon={
                    <GetIconButtonIcon
                      icon={QuestionTypeGraphs[singleSelectGraphTypeV1]}
                    />
                  }
                  variant={
                    selectedGraph ===
                    QuestionTypeGraphs[singleSelectGraphTypeV1]
                      ? "filled"
                      : "plain-color"
                  }
                  onClick={() => {
                    if (onIconClick) {
                      onIconClick(QuestionTypeGraphs[singleSelectGraphTypeV1]);
                    }
                  }}
                />
              );
            })}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.GRID_MULTI_SELECT_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(
              GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1,
            ).map((gridMultiSelectGraphTypeV1, index) => {
              return (
                <IconButton
                  key={index}
                  name={gridMultiSelectGraphTypeV1}
                  size="small"
                  icon={
                    <GetIconButtonIcon
                      icon={QuestionTypeGraphs[gridMultiSelectGraphTypeV1]}
                    />
                  }
                  variant={
                    selectedGraph ===
                    QuestionTypeGraphs[gridMultiSelectGraphTypeV1]
                      ? "filled"
                      : "plain-color"
                  }
                  onClick={() => {
                    if (onIconClick) {
                      onIconClick(
                        QuestionTypeGraphs[gridMultiSelectGraphTypeV1],
                      );
                    }
                  }}
                />
              );
            })}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(MultiSelectGraphTypeV1.MultiSelectGraphTypeV1).map(
              (multiSelectGraphTypeV1, index) => {
                return (
                  <IconButton
                    key={index}
                    name={multiSelectGraphTypeV1}
                    size="small"
                    icon={
                      <GetIconButtonIcon
                        icon={QuestionTypeGraphs[multiSelectGraphTypeV1]}
                      />
                    }
                    variant={
                      selectedGraph ===
                      QuestionTypeGraphs[multiSelectGraphTypeV1]
                        ? "filled"
                        : "plain-color"
                    }
                    onClick={() => {
                      if (onIconClick) {
                        onIconClick(QuestionTypeGraphs[multiSelectGraphTypeV1]);
                      }
                    }}
                  />
                );
              },
            )}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
        return (
          <Stack direction="row" gap={spacing.spaceXXS}>
            {Object.values(RankingGraphTypeV1.RankingGraphTypeV1).map(
              (rankingGraphTypeV1, index) => {
                return (
                  <IconButton
                    key={index}
                    name={rankingGraphTypeV1}
                    size="small"
                    icon={
                      <GetIconButtonIcon
                        icon={QuestionTypeGraphs[rankingGraphTypeV1]}
                      />
                    }
                    variant={
                      selectedGraph === QuestionTypeGraphs[rankingGraphTypeV1]
                        ? "filled"
                        : "plain-color"
                    }
                    onClick={() => {
                      if (onIconClick) {
                        onIconClick(QuestionTypeGraphs[rankingGraphTypeV1]);
                      }
                    }}
                  />
                );
              },
            )}
          </Stack>
        );
      }
      case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL:
      case SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL: {
        return <></>;
      }
    }
  },
);

interface ChartSelectionProps {
  border: BorderStyle;
  cornerRadius: CornerRadius;
  spacing: Spacing;
  surveyQuestionViewType: SurveyQuestionViewStoreNames;
  selectedGraph: QuestionTypeGraphs;
  onChartIconClick: (graphType: QuestionTypeGraphs) => void;
  tokens: FoundationColorTokens<string>;
}

export const ChartSelection = observer(
  ({
    border,
    cornerRadius,
    spacing,
    surveyQuestionViewType,
    selectedGraph,
    onChartIconClick,
    tokens,
  }: ChartSelectionProps): ReactElement => {
    return surveyQuestionViewType ===
      SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL ? (
      <></>
    ) : (
      <Stack
        borderRadius={cornerRadius.radiusSM}
        border={border.default}
        padding={spacing.spaceXXS}
        gap={spacing.spaceXXS}
        width="fit-content"
        height="fit-content"
        className={HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME}
      >
        <GraphIcons
          selectedGraph={selectedGraph}
          surveyQuestionViewType={surveyQuestionViewType}
          spacing={spacing}
          onIconClick={onChartIconClick}
          tokens={tokens}
        />
      </Stack>
    );
  },
);
