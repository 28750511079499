import { getType, Instance } from "mobx-state-tree";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  SurveyQuestionViewStoreNames,
  SurveyViewDetailsStore,
  UpdateQuestionGraphTypeRPCErrors,
} from "../stores/SurveyViewDetailsStore";
import { RegularSingleSelectQuestionView } from "./RegularSingleSelectQuestionView";
import { RegularSingleSelectQuestionModelV1 } from "../models/RegularSingleSelectQuestionModelV1";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
  Snackbar,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle, ZERO_VALUE } from "@pulse/shared-components";
import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { NPSSingleSelectQuestionModelV1 } from "../models/NPSSingleSelectQuestionModelV1";
import { NPSSingleSelectQuestionView } from "./NPSSingleSelectQuestionView";
import { SingleSelectQuestionWithProportionModelV1 } from "../models/SingleSelectQuestionWithProportionModelV1";
import { SingleSelectQuestionWithProportionView } from "./SingleSelectQuestionWithProportionView";
import { OpenEndedQuestionView } from "./OpenEndedQuestionView";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";
import {
  NavigateToFunctions,
  processSurveyViewParams,
} from "../../../routes/RoutesHelper";
import { RankingQuestionView } from "./RankingQuestionView";
import { RankingQuestionViewModelV1 } from "../models/RankingQuestionViewModelV1";
import { MultiSelectQuestionView } from "./MultiSelectQuestionView";
import { MultiSelectQuestionViewModelV1 } from "../models/MultiSelectQuestionViewModelV1";
import { QuestionViewSectionHeader } from "./QuestionViewSectionHeader";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import { FileX2 } from "lucide-react";
import { GroupQuestionViewModelV1 } from "../models/GroupQuestionViewModelV1";
import { GroupQuestionView } from "./GroupQuestionView";
import { GridQuestionViewV1Model } from "../models/GridQuestionV1Model";
import { GridQuestionView } from "./gridView/GridQuestionView";
import { QuestionViewGraphTypeV1 } from "@pulse/pulse-rpcs";
import { ButtonProps } from "./QuestionView";
import { SignInStore } from "../../auth/store/SignInStore";

export interface UpdateQuestionViewParams {
  navigateTo: NavigateToFunctions;
  updateQuestionView: (
    projectId: string,
    surveyId: string,
    surveyViewId: string,
    surveyQuestionViewId: string,
    questionViewGraphType: QuestionViewGraphTypeV1.QuestionViewGraphTypeV1,
    onResponse: () => void,
  ) => Promise<void>;
}

interface QuestionViewsProps {
  surveyViewDetailsStore: Instance<typeof SurveyViewDetailsStore>;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  navigateTo: NavigateToFunctions;
  signInStore: Instance<typeof SignInStore>;
}

export const QuestionViews = observer(
  ({
    surveyViewDetailsStore,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    navigateTo,
    signInStore,
  }: QuestionViewsProps): ReactElement => {
    const questionViewsSectionComponentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (
        surveyViewDetailsStore.isLazyRPCLoading &&
        questionViewsSectionComponentRef.current
      ) {
        questionViewsSectionComponentRef.current.scrollTop =
          questionViewsSectionComponentRef.current?.scrollHeight;
      }
    }, [surveyViewDetailsStore.isLazyRPCLoading]);

    const isPageScrolledToBottom = useCallback((): boolean => {
      return (
        Math.abs(
          (questionViewsSectionComponentRef.current?.scrollHeight ??
            ZERO_VALUE) -
            ((questionViewsSectionComponentRef.current?.scrollTop ??
              ZERO_VALUE) +
              (questionViewsSectionComponentRef.current?.clientHeight ??
                ZERO_VALUE)),
        ) <= 1
      );
    }, [questionViewsSectionComponentRef.current]);

    const onScroll = useMemo(() => {
      return (): void => {
        const atBottom = isPageScrolledToBottom();
        if (
          atBottom &&
          !surveyViewDetailsStore.isLastElementReached &&
          !surveyViewDetailsStore.isLazyRPCLoading
        ) {
          processSurveyViewParams(
            async (
              surveyViewId: string,
              surveyId: string,
              projectId: string,
            ): Promise<void> => {
              await surveyViewDetailsStore.getPaginatedSurveyViewResults(
                surveyViewId,
                surveyId,
                projectId,
                true,
              );
            },
            navigateTo,
          );
        }
      };
    }, [isPageScrolledToBottom, surveyViewDetailsStore, navigateTo]);

    useEffect(() => {
      if (questionViewsSectionComponentRef.current) {
        questionViewsSectionComponentRef.current?.addEventListener(
          "scroll",
          onScroll,
        );
      }
    }, []);

    const getErrorSnackbarMessage = (): string => {
      switch (surveyViewDetailsStore.rpcError) {
        case UpdateQuestionGraphTypeRPCErrors.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t("surveyDashboard.updateGraphViewUnexpectedErrorMessage");
      }
    };

    return (
      <>
        <QuestionViewSectionHeader
          spacing={spacing}
          surveyViewDetailsStore={surveyViewDetailsStore}
          shadow={boxShadow}
          t={t}
          tokens={tokens}
          typography={typography}
          border={border}
          cornerRadius={cornerRadius}
          navigateTo={navigateTo}
          questionViewsSectionComponentRef={questionViewsSectionComponentRef}
          signInStore={signInStore}
        />
        <Snackbar
          onClose={(): void => {
            surveyViewDetailsStore.resetRPCError();
          }}
          isOpen={surveyViewDetailsStore.isErrorSnackbarVisible}
          message={getErrorSnackbarMessage()}
          severity="error"
        />
        {surveyViewDetailsStore.isNoDataPresent ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={spacing.spaceXS}
            height="100%"
          >
            <FileX2
              {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
            />
            <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
              {t("surveyDashboard.noDataFound")}
            </Typography>
          </Stack>
        ) : (
          <Stack
            ref={questionViewsSectionComponentRef}
            paddingY={spacing.spaceXL}
            gap={spacing.spaceXL}
            height="100%"
            width="100%"
            alignItems="center"
            overflow="auto"
          >
            {surveyViewDetailsStore.surveyQuestionViews.map(
              (surveyQuestionView, index) => {
                switch (getType(surveyQuestionView).name) {
                  case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
                    return (
                      <RegularSingleSelectQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof RegularSingleSelectQuestionModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        isSurveyViewOptionDisplayTextSelected={
                          surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
                        }
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL: {
                    return (
                      <SingleSelectQuestionWithProportionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof SingleSelectQuestionWithProportionModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL: {
                    return (
                      <NPSSingleSelectQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof NPSSingleSelectQuestionModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
                    return (
                      <OpenEndedQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof OpenEndedQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        navigateTo={navigateTo}
                        tableFilter={surveyViewDetailsStore.getFilters()}
                        openEndedQuestionType={
                          (
                            surveyQuestionView as Instance<
                              typeof OpenEndedQuestionViewModelV1
                            >
                          ).getPaginatedOpenEndedResponsesRPCIndividualEnum
                        }
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
                    return (
                      <MultiSelectQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof MultiSelectQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        isSurveyViewOptionDisplayTextSelected={
                          surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
                        }
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
                    return (
                      <RankingQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof RankingQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        isSurveyViewOptionDisplayTextSelected={
                          surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
                        }
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL: {
                    return (
                      <GroupQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof GroupQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        isSurveyViewOptionDisplayTextSelected={
                          surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
                        }
                        navigateTo={navigateTo}
                        tableFilter={surveyViewDetailsStore.getFilters()}
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL: {
                    return (
                      <GridQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof GridQuestionViewV1Model
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        updateQuestionViewParams={{
                          navigateTo,
                          updateQuestionView:
                            surveyViewDetailsStore.updateQuestionGraphType,
                        }}
                        isSurveyViewOptionDisplayTextSelected={
                          surveyViewDetailsStore.isSurveyViewOptionDisplayTextSelected
                        }
                        navigateTo={navigateTo}
                        tableFilter={surveyViewDetailsStore.getFilters()}
                        allowSPoCToSelectGraphType={
                          surveyViewDetailsStore.isSPoCChangeGraphAllowed
                        }
                        signInStore={signInStore}
                      />
                    );
                  }
                }
              },
            )}
            {surveyViewDetailsStore.isLazyRPCLoading && (
              <LoadingIndicator
                isLoading={true}
                variant="container"
                loadingText={t("surveyDashboard.lazyLoadingText")}
              />
            )}
          </Stack>
        )}
      </>
    );
  },
);

export const getButtonProps = (
  updateQuestionViewParams: UpdateQuestionViewParams | undefined,
  surveyQuestionViewId: string,
  questionViewGraphType: QuestionViewGraphTypeV1.QuestionViewGraphTypeV1,
  isGraphSelectionUpdated: boolean,
  updateSelectedGraphTypeWithUpdatedGraph: () => void,
): ButtonProps | undefined => {
  if (updateQuestionViewParams) {
    const buttonProps: ButtonProps = {
      onSaveChangeClick: () => {
        if (updateQuestionViewParams) {
          processSurveyViewParams(
            async (
              surveyViewId: string,
              surveyId: string,
              projectId: string,
            ) => {
              await updateQuestionViewParams.updateQuestionView(
                projectId,
                surveyId,
                surveyViewId,
                surveyQuestionViewId,
                questionViewGraphType,
                updateSelectedGraphTypeWithUpdatedGraph,
              );
            },
            updateQuestionViewParams.navigateTo,
          );
        }
      },
      isSaveChangeDisabled: !isGraphSelectionUpdated,
    };
    return buttonProps;
  } else {
    return undefined;
  }
};
