import React, { ReactElement, useCallback, useEffect } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { getSurveyStatusChipProps } from "../../utils/getSurveyStatusChipProps";
import {
  useSignInStore,
  useSurveyStore,
  useSurveyViewDetailsStore,
} from "../../store/Hooks";
import {
  processProjectParams,
  processSurveyViewParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import { useTranslation } from "react-i18next";
import { ProjectDetailsTabs } from "../../projects/pages/ProjectDetailsPage";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { observer } from "mobx-react";
import {
  LoadingIndicator,
  useCornerRadius,
  useFoundationColorTokens,
  useShadow,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { QuestionViews } from "../components/QuestionViews";
import { useBorder } from "@pulse/shared-components";

export const SurveyViewDetails = observer((): ReactElement => {
  const surveyStore = useSurveyStore();
  const navigateTo = useRouteNavigator();
  const { t } = useTranslation();
  const errorStore = useNetworkingErrorStore();
  const surveyViewDetailsStore = useSurveyViewDetailsStore();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  const typography = useTypography();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const boxShadow = useShadow();
  const signInStore = useSignInStore();

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const getRespondentColumnNames = useCallback(async (): Promise<void> => {
    await processProjectParams(async (projectId: string) => {
      surveyViewDetailsStore.getRespondentColumnNames(projectId);
    }, navigateTo);
  }, [surveyViewDetailsStore]);

  const getPaginatedSurveyViewResults = useCallback(async (): Promise<void> => {
    await processSurveyViewParams(
      async (surveyViewId: string, surveyId: string, projectId: string) => {
        surveyViewDetailsStore.getPaginatedSurveyViewResults(
          surveyViewId,
          surveyId,
          projectId,
          false,
        );
      },
      navigateTo,
    );
  }, [surveyStore, surveyViewDetailsStore]);

  useEffect(() => {
    getRespondentColumnNames();
    getPaginatedSurveyViewResults();
    return () => {
      surveyViewDetailsStore.resetStoreData();
    };
  }, []);

  return (
    <DetailSectionComponent
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={true}
      mainHeaderProps={{
        title: surveyStore.surveyName,
        prefersLargeTitle: true,
        chipProps:
          surveyStore.surveyStatus &&
          getSurveyStatusChipProps(surveyStore.surveyStatus, t),
        actionElement: undefined,
        backButtonCallback: () => {
          processProjectParams(async (projectId): Promise<void> => {
            navigateTo.projectDetails(projectId, ProjectDetailsTabs.Dashboard);
          }, navigateTo);
        },
      }}
      isChildHeightVariable={
        surveyViewDetailsStore.isNoDataPresent ? false : true
      }
      isOverflowHidden={true}
      onLogoClickCallback={() => {
        navigateTo.project();
      }}
    >
      <LoadingIndicator isLoading={surveyViewDetailsStore.isRPCLoading} />
      <QuestionViews
        surveyViewDetailsStore={surveyViewDetailsStore}
        spacing={spacing}
        t={t}
        tokens={tokens}
        typography={typography}
        border={border}
        cornerRadius={cornerRadius}
        boxShadow={boxShadow}
        navigateTo={navigateTo}
        signInStore={signInStore}
      />
    </DetailSectionComponent>
  );
});
