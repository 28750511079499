import { Instance } from "mobx-state-tree";
import React, { ReactElement, useState } from "react";
import { SurveyDashboardViewModel } from "../models/SurveyDashboardViewModel";
import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  EMPTY_LIST_LENGTH,
  FeatureInDevelopmentSnackbar,
  getIconProps,
  ICON_SIZE,
} from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Chip,
  Typography as LeoTypography,
  RadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { FileBarChart2 } from "lucide-react";
import { TFunction } from "i18next";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { EditSurveyView } from "./EditSurveyView";
import { ManageSurveyViewSPoCs } from "./manageSurveyViewSPoCs/ManageSurveyViewSPoCs";
import { ReportsStore } from "../stores/ReportsStore";
import { SignInStore } from "../../auth/store/SignInStore";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { DeleteSurveyView } from "./DeleteSurveyView";

interface ActionElementProps {
  surveyDashboardView: Instance<typeof SurveyDashboardViewModel>;
  t: TFunction;
  spacing: Spacing;
  border: BorderStyle;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  radioButtonTokens: RadioButtonColorTokens<string>;
  navigateTo: NavigateToFunctions;
  reportStore: Instance<typeof ReportsStore>;
  signInStore: Instance<typeof SignInStore>;
}

const ActionElement = observer(
  ({
    surveyDashboardView,
    t,
    spacing,
    border,
    tokens,
    typography,
    radioButtonTokens,
    navigateTo,
    reportStore,
    signInStore,
  }: ActionElementProps): ReactElement => {
    const [
      isFeatureInDevelopmentSnackbarVisible,
      setIsFeatureInDevelopmentSnackbarVisible,
    ] = useState(false);
    if (
      !signInStore.isPrivilegeGranted(
        UserPrivileges.processSurveyDashboardViews,
      )
    ) {
      return <></>;
    }
    if (surveyDashboardView.isMasterView) {
      return <Chip label={t("surveyDashboard.master")} color={"blue"} />;
    } else {
      return (
        <Stack direction="row" gap={spacing.spaceXS}>
          <FeatureInDevelopmentSnackbar
            isSnackbarVisible={isFeatureInDevelopmentSnackbarVisible}
            setIsSnackbarVisible={setIsFeatureInDevelopmentSnackbarVisible}
          />
          <EditSurveyView
            surveyDashboardView={surveyDashboardView}
            t={t}
            spacing={spacing}
            border={border}
            tokens={tokens}
            typography={typography}
            radioButtonTokens={radioButtonTokens}
            navigateTo={navigateTo}
            reportStore={reportStore}
          />
          <ManageSurveyViewSPoCs
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            manageSurveyViewSPoCsModel={
              surveyDashboardView.manageSurveyViewSPoCs
            }
            navigateTo={navigateTo}
            border={border}
            setSurveyDashboardViews={reportStore.setSurveyDashboardViews}
          />
          <DeleteSurveyView
            surveyDashboardView={surveyDashboardView}
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            reportStore={reportStore}
          />
        </Stack>
      );
    }
  },
);

interface SurveyDashboardViewsProps {
  border: BorderStyle;
  cornerRadius: CornerRadius;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  radioButtonTokens: RadioButtonColorTokens<string>;
  reportStore: Instance<typeof ReportsStore>;
  signInStore: Instance<typeof SignInStore>;
}

export const SurveyDashboardViews = observer(
  ({
    border,
    cornerRadius,
    spacing,
    tokens,
    typography,
    t,
    navigateTo,
    radioButtonTokens,
    reportStore,
    signInStore,
  }: SurveyDashboardViewsProps): ReactElement => {
    return (
      <>
        {reportStore.surveyDashboardViews.length !== EMPTY_LIST_LENGTH && (
          <Stack
            border={border.default}
            borderRadius={cornerRadius.radiusXS}
            divider={<DividerComponent orientation="horizontal" width="100%" />}
            width="1024px"
            marginTop={spacing.spaceXL}
          >
            {reportStore.surveyDashboardViews.map((surveyDashboardView) => {
              return (
                <Stack
                  key={surveyDashboardView.surveyViewId}
                  direction="row"
                  padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
                  gap={spacing.spaceXS}
                  justifyContent={"space-between"}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  borderRadius={cornerRadius.radiusXS}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    gap={spacing.spaceXS}
                    alignItems="center"
                    onClick={(): void => {
                      processSurveyParams(
                        async (surveyId: string, projectId: string) => {
                          navigateTo.surveyView(
                            projectId,
                            surveyId,
                            surveyDashboardView.surveyViewId,
                          );
                        },
                        navigateTo,
                      );
                    }}
                  >
                    <FileBarChart2
                      {...getIconProps(tokens.icon, ICON_SIZE.medium)}
                    />
                    <Typography {...typography.s1} color={tokens.label}>
                      {surveyDashboardView.surveyViewName}
                    </Typography>
                  </Stack>
                  <ActionElement
                    surveyDashboardView={surveyDashboardView}
                    t={t}
                    spacing={spacing}
                    border={border}
                    tokens={tokens}
                    typography={typography}
                    radioButtonTokens={radioButtonTokens}
                    navigateTo={navigateTo}
                    reportStore={reportStore}
                    signInStore={signInStore}
                  />
                </Stack>
              );
            })}
          </Stack>
        )}
      </>
    );
  },
);
