import { Box, Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { ReactElement } from "react";
import {
  DASHBOARD_CHART_COLORS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../utils/constants";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { PRIMARY_FONT } from "@pulse/shared-components";
import { SurveySettingsEnums } from "@pulse/pulse-rpcs";
import { RICH_TEXT_DEFAULT_FONT_COLOR } from "@pulse/shared-components";
import { RTERegularTextType } from "@pulse/shared-components";

interface AnswerCount {
  count: number;
  answer: string;
}

export interface DataPropsForGridStackedAndGroupedBar {
  option: string | null;
  answerCounts: AnswerCount[];
  id: string;
  rowOptionText: string;
}

interface MeanProps {
  mean: string | null;
  gridQuartileColor: string | null;
}

export interface DataProps {
  option: string | null;
  count: number;
  percentage: string;
  id: string;
  meanProps?: MeanProps;
}
export interface DataPropsForRanking {
  option: string | null;
  count: number[];
  id: string;
}

interface DataSectionProps {
  spacing: Spacing;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  total: number | null;
  data:
    | DataProps[]
    | DataPropsForRanking[]
    | DataPropsForGridStackedAndGroupedBar[];
  border: BorderStyle;
  isColoredSwatchRequired: boolean;
  customOptionHeaders: string[] | undefined;
  isSurveyViewOptionDisplayTextSelected: boolean;
}

enum DataPropNames {
  // `percentage` is expected in `data` for questions other than ranking and grid.
  PERCENTAGE = "percentage",
  // answerCounts is expected in `data` for grid questions.
  answerCounts = "answerCounts",
}

export const DataSection = ({
  spacing,
  t,
  typography,
  tokens,
  cornerRadius,
  total,
  data,
  border,
  isColoredSwatchRequired,
  customOptionHeaders,
  isSurveyViewOptionDisplayTextSelected,
}: DataSectionProps): ReactElement => {
  let totalRanks: number | undefined;
  if (
    !(DataPropNames.PERCENTAGE in data[0]) &&
    !(DataPropNames.answerCounts in data[0])
  ) {
    totalRanks = data[0].count.length;
  }
  const isRankingQuestion = DataPropNames.PERCENTAGE in data[0] ? false : true;
  const B2LabelSubtleTypography = ({
    text,
  }: {
    text: string;
  }): ReactElement => {
    return (
      <Typography {...typography.b2} color={tokens.labelSubtle} width="100%">
        {text}
      </Typography>
    );
  };

  const S1LabelTypography = ({ text }: { text: string }): ReactElement => {
    return (
      <Typography {...typography.s1} color={tokens.label} width="100%">
        {text}
      </Typography>
    );
  };

  const B1LabelTypography = ({ text }: { text: string }): ReactElement => {
    return (
      <Typography {...typography.b1} color={tokens.label} width="100%">
        {text}
      </Typography>
    );
  };

  const B2LabelTypography = ({ text }: { text: string }): ReactElement => {
    return (
      <Typography
        {...typography.b2}
        color={tokens.label}
        width="100%"
        textAlign="center"
      >
        {text}
      </Typography>
    );
  };
  const tdCommonProps = {
    borderTop: border.default,
    padding: `${spacing.spaceXS} ${ZERO_VALUE}`,
  };

  return (
    <table
      style={{
        borderSpacing: ZERO_VALUE,
        paddingTop: spacing.spaceXL,
        backgroundColor: tokens.backgroundSubtle,
        borderRadius: cornerRadius.radiusXS,
        padding: spacing.spaceXL,
        width: "100%",
        maxWidth: `${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`,
      }}
    >
      <thead style={{ borderSpacing: ZERO_VALUE }}>
        <tr>
          <td
            style={{
              paddingBottom: spacing.spaceSM,
              textAlign: "left",
              maxWidth: "726px",
            }}
          >
            <B2LabelSubtleTypography
              text={t("surveyDashboard.views.answers")}
            />
          </td>
          {customOptionHeaders ? (
            <>
              {customOptionHeaders?.map((option, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      paddingBottom: spacing.spaceSM,
                      maxWidth: "108px",
                    }}
                  >
                    <Stack
                      width="100%"
                      sx={{
                        "& .ProseMirror": {
                          textAlign: "center",
                        },
                      }}
                    >
                      {isSurveyViewOptionDisplayTextSelected ? (
                        <RichTextEditor
                          name={option ?? EMPTY_CHARACTER}
                          typography={richTextTypography(
                            undefined,
                            undefined,
                            "labelSubtle",
                          )}
                          mode="preview"
                          borderOnPreview={false}
                          initialValue={option ?? EMPTY_CHARACTER}
                          supports={RICH_TEXT_MARKDOWN_PROPS}
                        />
                      ) : (
                        <B2LabelSubtleTypography
                          text={option ?? EMPTY_CHARACTER}
                        />
                      )}
                    </Stack>
                  </td>
                );
              })}
            </>
          ) : isRankingQuestion ? (
            <>
              {Array.from(
                { length: data.length ?? ZERO_VALUE },
                (_, rankHeader) => {
                  return (
                    <td
                      key={rankHeader}
                      style={{
                        paddingBottom: spacing.spaceSM,
                        width: "80px",
                        textAlign: "center",
                      }}
                    >
                      <B2LabelSubtleTypography text={`${rankHeader + 1}`} />
                    </td>
                  );
                },
              )}
            </>
          ) : (
            <>
              <td
                style={{
                  paddingBottom: spacing.spaceSM,
                  width: "80px",
                }}
              >
                <B2LabelSubtleTypography
                  text={t("surveyDashboard.views.percentage")}
                />
              </td>
              <td
                style={{
                  paddingBottom: spacing.spaceSM,
                  textAlign: "right",
                  width: "64px",
                  paddingLeft: spacing.spaceXS,
                }}
              >
                <B2LabelSubtleTypography
                  text={t("surveyDashboard.views.count")}
                />
              </td>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((dataProps, index) => {
          const isRankingData = DataPropNames.PERCENTAGE in dataProps;
          return (
            <tr key={dataProps.id} style={{ gap: spacing.spaceXS }}>
              <td style={{ ...tdCommonProps, paddingRight: spacing.spaceXS }}>
                <Stack
                  gap={spacing.spaceXXS}
                  direction="row"
                  alignItems="center"
                >
                  {isColoredSwatchRequired && (
                    <Box
                      height="12px"
                      width="12px"
                      minWidth="12px"
                      borderRadius={cornerRadius.radiusMax}
                      sx={{
                        backgroundColor:
                          DASHBOARD_CHART_COLORS[
                            index % DASHBOARD_CHART_COLORS.length
                          ],
                      }}
                    />
                  )}
                  {isSurveyViewOptionDisplayTextSelected ? (
                    <RichTextEditor
                      name={`${index}`}
                      typography={richTextTypography(
                        PRIMARY_FONT,
                        SurveySettingsEnums.FontSizeScale.FontSizeScale
                          .DEFAULT_SCALE,
                        RICH_TEXT_DEFAULT_FONT_COLOR,
                        true,
                        RTERegularTextType.BACK_OFFICE,
                      )}
                      mode="preview"
                      borderOnPreview={false}
                      initialValue={dataProps.option ?? EMPTY_CHARACTER}
                      supports={RICH_TEXT_MARKDOWN_PROPS}
                    />
                  ) : (
                    <Typography
                      {...typography.b2}
                      color={tokens.label}
                      textAlign="center"
                    >
                      {dataProps.option ?? EMPTY_CHARACTER}
                    </Typography>
                  )}
                </Stack>
              </td>
              {isRankingData ? (
                <>
                  <td style={{ ...tdCommonProps }}>
                    <Stack
                      width="80px"
                      justifyContent="center"
                      padding={`${spacing.spaceXXS} ${spacing.spaceXS}`}
                      sx={{
                        background: tokens.backgroundElevatedLevel1,
                      }}
                    >
                      <B2LabelTypography text={`${dataProps.percentage}%`} />
                    </Stack>
                  </td>
                  <td style={{ ...tdCommonProps, textAlign: "right" }}>
                    <B1LabelTypography text={`${dataProps.count}`} />
                  </td>
                </>
              ) : DataPropNames.answerCounts in dataProps ? (
                <>
                  {dataProps.answerCounts.map((item, itemIndex) => {
                    return (
                      <td
                        key={itemIndex}
                        style={{
                          ...tdCommonProps,
                          textAlign: "center",
                        }}
                      >
                        <B1LabelTypography text={`${item.count}`} />
                      </td>
                    );
                  })}
                </>
              ) : (
                <>
                  {dataProps.count.map((item, itemIndex) => {
                    return (
                      <td
                        key={itemIndex}
                        style={{
                          ...tdCommonProps,
                          textAlign: "center",
                        }}
                      >
                        <B1LabelTypography text={`${item}`} />
                      </td>
                    );
                  })}
                </>
              )}
            </tr>
          );
        })}
        {total !== null && (
          <tr>
            <td style={{ ...tdCommonProps }}>
              <S1LabelTypography text={t("surveyDashboard.views.total")} />
            </td>
            {totalRanks && isRankingQuestion ? (
              <>
                {Array.from({ length: totalRanks - 1 }, (_, rank) => {
                  return <td key={rank} style={{ ...tdCommonProps }} />;
                })}
              </>
            ) : (
              <td style={{ ...tdCommonProps }} />
            )}
            <td
              style={{
                textAlign: totalRanks && isRankingQuestion ? "center" : "right",
                ...tdCommonProps,
              }}
            >
              <S1LabelTypography text={`${total}`} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
