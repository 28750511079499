import {
  Button,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { OpenEndedResponsesModel } from "../models/OpenEndedResponsesModel";
import { Instance } from "mobx-state-tree";
import { ViewOpenEndedResponsesDialog } from "./ViewOpenEndedResponsesDialog";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";

interface ViewOpenEndedResponsesProps {
  t: TFunction;
  openEndedResponsesStore: Instance<typeof OpenEndedResponsesModel>;
  spacing: Spacing;
  navigateTo: NavigateToFunctions;
  surveyQuestionViewId: string;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
}

export const ViewOpenEndedResponses = observer(
  ({
    t,
    openEndedResponsesStore,
    spacing,
    navigateTo,
    surveyQuestionViewId,
    tokens,
    typography,
    tableFilter,
    openEndedQuestionType,
  }: ViewOpenEndedResponsesProps): React.ReactElement => {
    const [
      isViewOpenEndedResponsesDialogVisible,
      setIsViewOpenEndedResponsesDialogVisible,
    ] = useState(false);

    return (
      <>
        {isViewOpenEndedResponsesDialogVisible && (
          <ViewOpenEndedResponsesDialog
            isViewOpenEndedResponsesDialogVisible={
              isViewOpenEndedResponsesDialogVisible
            }
            setIsViewOpenEndedResponsesDialogVisible={
              setIsViewOpenEndedResponsesDialogVisible
            }
            openEndedResponsesStore={openEndedResponsesStore}
            t={t}
            spacing={spacing}
            navigateTo={navigateTo}
            surveyQuestionViewId={surveyQuestionViewId}
            tokens={tokens}
            typography={typography}
            tableFilter={tableFilter}
            openEndedQuestionType={openEndedQuestionType}
          />
        )}
        <Button
          label={t("surveyDashboard.viewResponses")}
          name={t("surveyDashboard.viewResponses")}
          size={"small"}
          variant={"plain-color"}
          onClick={(): void => {
            setIsViewOpenEndedResponsesDialogVisible(true);
          }}
        />
      </>
    );
  },
);
