import React, { ReactElement } from "react";
import { ManageQuestionViewsStore } from "../../stores/ManageQuestionViewsStore";
import { Instance } from "mobx-state-tree";
import { QuestionListItem } from "./QuestionListItem";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { Stack } from "@mui/material";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { observer } from "mobx-react";
import { SurveyQuestionViewsModel } from "../../models/SurveyQuestionViewsModel";

interface ManageQuestionViewsDialogChildProps {
  manageQuestionViewsStore: Instance<typeof ManageQuestionViewsStore>;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  border: BorderStyle;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  t: TFunction;
}

interface SortableQuestionListItemProps {
  question: Instance<typeof SurveyQuestionViewsModel>;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  t: TFunction;
  spacing: Spacing;
  isLastElement: boolean;
  index: number;
}
const SortableQuestionListItem = observer(
  ({
    question,
    border,
    cornerRadius,
    tokens,
    typography,
    t,
    spacing,
    isLastElement,
    index,
  }: SortableQuestionListItemProps): ReactElement => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: question.surveyQuestionViewId });

    return (
      <Stack
        ref={setNodeRef}
        direction="row"
        sx={{
          transform: CSS.Translate.toString(transform),
          transition,
        }}
        borderBottom={isLastElement ? undefined : border.default}
      >
        <QuestionListItem
          question={question}
          border={border}
          cornerRadius={cornerRadius}
          tokens={tokens}
          typography={typography}
          t={t}
          spacing={spacing}
          attributes={attributes}
          listeners={listeners}
          index={index}
        />
      </Stack>
    );
  },
);

export const ManageQuestionViewsDialogChild = observer(
  ({
    manageQuestionViewsStore,
    spacing,
    cornerRadius,
    border,
    tokens,
    typography,
    t,
  }: ManageQuestionViewsDialogChildProps): ReactElement => {
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    const handleDragEnd = (event: DragEndEvent): void => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        manageQuestionViewsStore.reorderQuestionnaire(
          `${active.id}`,
          `${over.id}`,
        );
      }
    };

    return (
      <Stack maxWidth="960px" overflow="hidden" width="100%">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={manageQuestionViewsStore.updatedSurveyQuestionViews.map(
              (surveyQuestionView) => surveyQuestionView.surveyQuestionViewId,
            )}
            strategy={verticalListSortingStrategy}
          >
            {manageQuestionViewsStore.updatedSurveyQuestionViews.map(
              (question, index) => {
                return (
                  <SortableQuestionListItem
                    key={question.surveyQuestionViewId}
                    question={question}
                    border={border}
                    cornerRadius={cornerRadius}
                    tokens={tokens}
                    typography={typography}
                    t={t}
                    spacing={spacing}
                    isLastElement={
                      index ===
                      manageQuestionViewsStore.surveyQuestionViews.length - 1
                    }
                    index={index}
                  />
                );
              },
            )}
          </SortableContext>
        </DndContext>
      </Stack>
    );
  },
);
