import { APIClient } from "@surya-digital/tedwig";
import { cast, flow, getRoot, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  OPEN_ENDED_RESPONSES_LIST_SIZE,
  UnexpectedError,
} from "../../utils/constants";
import { useGetPaginatedOpenEndedResponsesRPCClientImpl } from "../rpcs/RPC";
import {
  CommonErrors,
  NetworkingError,
  ZERO_VALUE,
} from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";

export enum ViewOpenEndedResponsesDialogState {
  ViewResponses = "VIEW_RESPONSES",
  Error = "ERROR",
  Success = "SUCCESS",
}

export const OpenEndedResponsesModel = types
  .model("OpenEndedResponsesModel", {
    pageIndex: types.optional(types.number, ZERO_VALUE),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetPaginatedOpenEndedResponsesRPC.RPCError),
        ),
        types.enumeration(Object.values(UnexpectedError)),
      ),
    ),
    isGetPaginatedOpenEndedResponsesRPCLoading: types.optional(
      types.boolean,
      true,
    ),
    totalItems: types.optional(types.number, ZERO_VALUE),
    openEndedResponses: types.array(types.string),
    viewOpenEndedResponsesDialogState: types.optional(
      types.enumeration(Object.values(ViewOpenEndedResponsesDialogState)),
      ViewOpenEndedResponsesDialogState.ViewResponses,
    ),
  })
  .actions((store) => ({
    resetStore: (): void => {
      store.viewOpenEndedResponsesDialogState =
        ViewOpenEndedResponsesDialogState.ViewResponses;
      store.rpcError = null;
      store.pageIndex = ZERO_VALUE;
      store.isGetPaginatedOpenEndedResponsesRPCLoading = false;
      store.totalItems = ZERO_VALUE;
      store.openEndedResponses.clear();
    },
    getPaginatedOpenEndedResponses: flow(function* (
      projectId: string,
      surveyId: string,
      surveyViewId: string,
      surveyQuestionViewId: string,
      pageIndex: number,
      tableFilter: TableFilter[],
      openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType,
    ) {
      store.isGetPaginatedOpenEndedResponsesRPCLoading = true;
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetPaginatedOpenEndedResponsesRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(surveyViewId),
          new LeoUUID(surveyQuestionViewId),
          pageIndex,
          OPEN_ENDED_RESPONSES_LIST_SIZE,
          tableFilter,
          openEndedQuestionType,
        );
        const {
          response,
          error,
        }: {
          response?: GetPaginatedOpenEndedResponsesRPC.Response;
          error?: GetPaginatedOpenEndedResponsesRPC.Errors.Errors;
        } =
          yield useGetPaginatedOpenEndedResponsesRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          store.totalItems = response.totalItems;
          store.openEndedResponses = cast(
            response.openEndedResponses.map(
              (openEndedResponse) => openEndedResponse,
            ),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPaginatedOpenEndedResponsesRPC.RPCError
              .InvalidSurveyViewId: {
              errorStore.setError(NetworkingError.PageNotFound);
              break;
            }
            case GetPaginatedOpenEndedResponsesRPC.RPCError
              .DeletedColumnFilter: {
              store.rpcError =
                GetPaginatedOpenEndedResponsesRPC.RPCError.DeletedColumnFilter;
              store.viewOpenEndedResponsesDialogState =
                ViewOpenEndedResponsesDialogState.Error;
              break;
            }
            case GetPaginatedOpenEndedResponsesRPC.RPCError
              .IncorrectQuestionType: {
              store.rpcError =
                GetPaginatedOpenEndedResponsesRPC.RPCError.IncorrectQuestionType;
              store.viewOpenEndedResponsesDialogState =
                ViewOpenEndedResponsesDialogState.Error;
              break;
            }
            case GetPaginatedOpenEndedResponsesRPC.RPCError.InvalidPageIndex: {
              store.rpcError =
                GetPaginatedOpenEndedResponsesRPC.RPCError.InvalidPageIndex;
              store.viewOpenEndedResponsesDialogState =
                ViewOpenEndedResponsesDialogState.Error;
              break;
            }
            case GetPaginatedOpenEndedResponsesRPC.RPCError
              .InvalidQuestionViewId: {
              store.rpcError =
                GetPaginatedOpenEndedResponsesRPC.RPCError.InvalidQuestionViewId;
              store.viewOpenEndedResponsesDialogState =
                ViewOpenEndedResponsesDialogState.Error;
              break;
            }
            default: {
              store.rpcError = UnexpectedError.UnhandledError;
              console.error(
                `Unhandled error ${error.code} from GetPaginatedOpenEndedResponsesRPC`,
              );
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createView action in AddViewStore`,
          );
        }
      } finally {
        store.isGetPaginatedOpenEndedResponsesRPCLoading = false;
      }
    }),
  }));

export const createOpenEndedResponsesModel = (): Instance<
  typeof OpenEndedResponsesModel
> => {
  return OpenEndedResponsesModel.create();
};
