import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { ReactElement, useState } from "react";
import { ManageQuestionViewsDialog } from "./ManageQuestionViewsDialog";
import { ManageQuestionViewsStore } from "../../stores/ManageQuestionViewsStore";
import { Instance } from "mobx-state-tree";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { BorderStyle } from "@pulse/shared-components";

interface ManageQuestionViewsProps {
  t: TFunction;
  manageQuestionViewsStore: Instance<typeof ManageQuestionViewsStore>;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  questionViewsSectionComponentRef: React.RefObject<HTMLDivElement>;
}

export const ManageQuestionViews = observer(
  ({
    t,
    manageQuestionViewsStore,
    navigateTo,
    spacing,
    border,
    cornerRadius,
    tokens,
    typography,
    questionViewsSectionComponentRef,
  }: ManageQuestionViewsProps): ReactElement => {
    const [
      isManageQuestionViewsDialogVisible,
      setIsManageQuestionViewsDialogVisible,
    ] = useState(false);

    return (
      <>
        {isManageQuestionViewsDialogVisible && (
          <ManageQuestionViewsDialog
            isManageQuestionViewsDialogVisible={
              isManageQuestionViewsDialogVisible
            }
            setIsManageQuestionViewsDialogVisible={
              setIsManageQuestionViewsDialogVisible
            }
            t={t}
            manageQuestionViewsStore={manageQuestionViewsStore}
            navigateTo={navigateTo}
            spacing={spacing}
            border={border}
            cornerRadius={cornerRadius}
            tokens={tokens}
            typography={typography}
            questionViewsSectionComponentRef={questionViewsSectionComponentRef}
          />
        )}
        <Button
          name="manageQuestionViews"
          variant="outlined-neutral"
          size="small"
          label={t("common.manageQuestions")}
          onClick={(): void => {
            setIsManageQuestionViewsDialogVisible(true);
          }}
        />
      </>
    );
  },
);
