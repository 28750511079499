import {
  Code,
  GetPaginatedOpenEndedResponsesRPC,
  GroupChildGraphTypeV1,
  OpenEndedGraphTypeV1,
  QuestionViewGraphTypeV1,
  QuestionViewTypeV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import { QuestionTypeGraphs } from "../components/ChartSelection";
import {
  OpenEndedResultV1Model,
  createOpenEndedResultsV1Model,
} from "./OpenEndedResultV1Model";
import { DataProps } from "../components/DataSection";
import { TOP_N_OPEN_ENDED_WORDS } from "../../utils/constants";
import {
  createOpenEndedResponsesModel,
  OpenEndedResponsesModel,
} from "./OpenEndedResponsesModel";

export const OpenEndedQuestionViewModelV1 = types
  .model("OpenEndedQuestionViewModelV1", {
    sampleBreakdown: SampleBreakdownModel,
    openEndedGraphType: types.enumeration(
      "OpenEndedGraphTypeV1",
      Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1),
    ),
    updatedOpenEndedGraphType: types.enumeration(
      "OpenEndedGraphTypeV1",
      Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1),
    ),
    openEndedV1Results: types.array(OpenEndedResultV1Model),
    question: types.maybeNull(types.string),
    topNWords: types.optional(types.number, TOP_N_OPEN_ENDED_WORDS[0]),
    surveyQuestionViewId: types.string,
    groupChildQuestionCode: types.string,
    openEndedResponsesStore: OpenEndedResponsesModel,
  })
  .views((store) => ({
    get getPaginatedOpenEndedResponsesRPCIndividualEnum(): GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Individual {
      try {
        return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Individual();
      } catch (e) {
        console.error(`Failed to create object for individual question `);
        return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Individual();
      }
    },
    get getPaginatedOpenEndedResponsesRPCGroupEnum(): GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Group {
      try {
        return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Group(
          new Code(store.groupChildQuestionCode),
          new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.GroupEnums.ChildQuestionType.OpenEnded(),
        );
      } catch (e) {
        console.error(
          `Failed to create object with groupChildQuestionCode ${store.groupChildQuestionCode}`,
        );
        return new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.Group(
          new Code(store.groupChildQuestionCode),
          new GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.GroupEnums.ChildQuestionType.OpenEnded(),
        );
      }
    },
    get isGraphSelectionUpdated(): boolean {
      return store.updatedOpenEndedGraphType !== store.openEndedGraphType;
    },
    get openEndedResultData(): DataProps[] {
      return store.openEndedV1Results
        .map((openEndedResult): DataProps => {
          return {
            option: openEndedResult.answer,
            count: openEndedResult.count,
            percentage: openEndedResult.percentage,
            id: openEndedResult.answer,
          };
        })
        .slice(0, store.topNWords);
    },
    get questionViewGraphType(): QuestionViewGraphTypeV1.OpenEnded {
      return new QuestionViewGraphTypeV1.OpenEnded(
        store.updatedOpenEndedGraphType,
      );
    },
    get groupQuestionViewGraphtype(): GroupChildGraphTypeV1.GroupChildGraphTypeV1 {
      return new GroupChildGraphTypeV1.OpenEnded(
        store.updatedOpenEndedGraphType,
      );
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedOpenEndedGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedOpenEndedGraphType =
        OpenEndedGraphTypeV1.OpenEndedGraphTypeV1[
          updatedOpenEndedGraphType as keyof typeof OpenEndedGraphTypeV1.OpenEndedGraphTypeV1
        ];
    },
    setTopNWords: (topNWords: number): void => {
      store.topNWords = topNWords;
    },
    updateSelectedGraphTypeWithUpdatedGraph: (): void => {
      store.openEndedGraphType = store.updatedOpenEndedGraphType;
    },
  }));

export const createOpenEndedQuestionViewModelV1 = (
  openEndedQuestionView: QuestionViewTypeV1.OpenEnded,
  surveyQuestionViewId: string,
  groupChildQuestionCode: string,
  question: string | null = null,
): Instance<typeof OpenEndedQuestionViewModelV1> => {
  return OpenEndedQuestionViewModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      openEndedQuestionView.sampleBreakdown,
    ),
    openEndedGraphType: openEndedQuestionView.openEndedGraphType,
    updatedOpenEndedGraphType: openEndedQuestionView.openEndedGraphType,
    openEndedV1Results: createOpenEndedResultsV1Model(
      openEndedQuestionView.openEndedResults,
    ),
    question,
    surveyQuestionViewId,
    groupChildQuestionCode,
    openEndedResponsesStore: createOpenEndedResponsesModel(),
  });
};
