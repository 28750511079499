import React from "react";
import { Instance } from "mobx-state-tree";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { QuestionView } from "./QuestionView";
import { QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";
import { getButtonProps, UpdateQuestionViewParams } from "./QuestionViews";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import {
  GetPaginatedOpenEndedResponsesRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";
import { SignInStore } from "../../auth/store/SignInStore";

interface OpenEndedQuestionViewProps {
  surveyQuestionView: Instance<typeof OpenEndedQuestionViewModelV1>;
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  updateQuestionViewParams?: UpdateQuestionViewParams;
  navigateTo: NavigateToFunctions;
  tableFilter: TableFilter[];
  openEndedQuestionType: GetPaginatedOpenEndedResponsesRPC.RequestEnums.OpenEndedQuestionType.OpenEndedQuestionType;
  groupChildSurveyQuestionViewId?: string;
  allowSPoCToSelectGraphType: boolean;
  signInStore: Instance<typeof SignInStore>;
}

export const OpenEndedQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    updateQuestionViewParams,
    navigateTo,
    tableFilter,
    openEndedQuestionType,
    groupChildSurveyQuestionViewId,
    allowSPoCToSelectGraphType,
    signInStore,
  }: OpenEndedQuestionViewProps): React.ReactElement => {
    return (
      <QuestionView
        t={t}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        cornerRadius={cornerRadius}
        border={border}
        questionText={surveyQuestionView.question}
        sampleBreakdown={surveyQuestionView.sampleBreakdown}
        total={null}
        selectedGraph={
          QuestionTypeGraphs[surveyQuestionView.updatedOpenEndedGraphType]
        }
        surveyQuestionViewType={
          SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL
        }
        updateSelectedGraphType={surveyQuestionView.updateSelectedGraphType}
        data={surveyQuestionView.openEndedResultData}
        buttonProps={getButtonProps(
          updateQuestionViewParams,
          surveyQuestionView.surveyQuestionViewId,
          surveyQuestionView.questionViewGraphType,
          surveyQuestionView.isGraphSelectionUpdated,
          surveyQuestionView.updateSelectedGraphTypeWithUpdatedGraph,
        )}
        boxShadow={boxShadow}
        topNWordsProps={{
          selectedTopNWords: surveyQuestionView.topNWords,
          setSelectedTopNWords: surveyQuestionView.setTopNWords,
          openEndedResponsesStore: surveyQuestionView.openEndedResponsesStore,
          navigateTo,
          surveyQuestionViewId:
            groupChildSurveyQuestionViewId ??
            surveyQuestionView.surveyQuestionViewId,
          tableFilter,
          openEndedQuestionType,
        }}
        isChildQuestion={updateQuestionViewParams === undefined}
        isSurveyViewOptionDisplayTextSelected={true}
        allowSPoCToSelectGraphType={allowSPoCToSelectGraphType}
        signInStore={signInStore}
      />
    );
  },
);
