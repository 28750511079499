export const DASHBOARD_CHART_COLORS = [
  "#11b5ae",
  "#4046ca",
  "#f68512",
  "#de3c82",
  "#7e84fa",
  "#72e06a",
  "#167af3",
  "#7326d3",
  "#e8c600",
  "#cb5d02",
  "#008f5d",
  "#bce931",
];

export const DASHBOARD_CHART_PRIMARY_COLORS = [
  "#F1E7F3",
  "#E2D0E7",
  "#D4B8DB",
  "#C6A0CF",
  "#B788C3",
  "#A971B7",
  "#9A58AB",
  "#864B94",
  "#6F3E7C",
  "#593263",
  "#43254A",
  "#381F3E",
];

export const DASHBOARD_NPS_CHART_FOREGROUND_COLORS = {
  DETRACTOR_COLOR: "#F6354F",
  PASSIVE_COLOR: "#FCAD40",
  PROMOTER_COLOR: "#17AF64",
};

export const DASHBOARD_NPS_CHART_BACKGROUND_COLORS = {
  DETRACTOR_COLOR: "#FDD4DA",
  PASSIVE_COLOR: "#FEEED6",
  PROMOTER_COLOR: "#C4F7DE",
};

export const TOP_N_OPEN_ENDED_WORDS = [10, 20, 30, 40, 50];

export const PERCENTAGE_PRECISION = 2;

export const MEAN_BULLET_WIDTH_IN_PIXELS = 56;

export const RAG_PALETTE_COLORS = {
  LEFT_MOST: {
    lightMode: { unselected: "#FFD9DC", selected: "#CC1E2C" },
    darkMode: { unselected: "#570D13", selected: "#F7706A" },
  },
  FAR_LEFT: {
    lightMode: { unselected: "#FFE0DB", selected: "#DA4321" },
    darkMode: { unselected: "#581A0F", selected: "#F9855F" },
  },
  LEFT: {
    lightMode: { unselected: "#FFEEDA", selected: "#F69009" },
    darkMode: { unselected: "#5A3404", selected: "#FEB046" },
  },
  MIDDLE: {
    lightMode: { unselected: "#FFF2D9", selected: "#FFAA00" },
    darkMode: { unselected: "#5A3C00", selected: "#FFBE3D" },
  },
  RIGHT: {
    lightMode: { unselected: "#EAFAE1", selected: "#728F1B" },
    darkMode: { unselected: "#32370E", selected: "#94B64E" },
  },
  FAR_RIGHT: {
    lightMode: { unselected: "#E3FCE4", selected: "#438623" },
    darkMode: { unselected: "#253513", selected: "#71B353" },
  },
  RIGHT_MOST: {
    lightMode: { unselected: "#D9FFE8", selected: "#00792F" },
    darkMode: { unselected: "#113219", selected: "#3EAF5A" },
  },
};

export const PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS = 60;
export const QUESTION_VIEW_MAX_WIDTH_IN_PIXELS = 912;
export const CHILD_QUESTION_VIEW_MAX_WIDTH_IN_PIXELS = 812;

export enum UnexpectedError {
  UnhandledError = "UNHANDLED_ERROR",
}

export const HIDDEN_ELEMENT_WHILE_DOWNLOADING_GRAPH_CLASS_NAME =
  "hidden-element-while-downloading-graph";
export const MAXIMUM_QUARTILE_CONFIGURATIONS = 5;
export const QUESTION_VIEW_GRAPH_TYPE_SUFFIX = "QuestionView";
export const OPEN_ENDED_RESPONSES_LIST_SIZE = 10;
export const OPEN_ENDED_RESPONSES_LIST_DEFAULT_INDEX = 0;
